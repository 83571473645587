import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '~/components/layout'
import SEOEn from '~/components/seoEn'

const MentionsPage = ({ data: { mentionsFr, mentionsEn } }) => {
  const {
    body: { content },
  } = mentionsEn.nodes[0]
  const {
    body: { background },
  } = mentionsFr.nodes[0]
  const seo = {
    title: 'Legal notices',
    metaDescription: 'Discover information about the website',
  }
  return (
    <Layout isEn>
      <SEOEn seo={seo} />
      <div
        className="bg-no-repeat bg-cover h-full"
        style={{ backgroundImage: `url(${background.url})` }}
      >
        <div className={`grid grid-cols-12 container laptop:m-auto`}>
          <div className="col-start-1 col-end-13 min-h-screen">
            <ReactMarkdown className="bg-white p-3 mb-10 push-content tablet:mx-5">
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query mentionsQueryAll {
    mentionsEn: allStrapiMentions(filter: { locale: { eq: "en" } }) {
      nodes {
        body {
          content
          background {
            url
          }
        }
      }
    }
    mentionsFr: allStrapiMentions(filter: { locale: { eq: "fr-FR" } }) {
      nodes {
        body {
          content
          background {
            url
          }
        }
      }
    }
  }
`

export default MentionsPage
